<template>
  <v-autocomplete
    v-model="selected"
    :search-input.sync="search"
    :loading="loading"
    :items="items"
    label="Campaign"
    item-value="uuid"
    item-text="name"
    :return-object="returnObject"
    :clearable="clearable"
    :error-messages="errorMessages"
  >
    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>

    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small :class="getCampaignStatus(data.item).class"
            >fas fa-circle</v-icon
          >
          <span v-text="getCampaignStatus(data.item).label"></span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import date_to_age from "@/helpers/filters/date_to_age";
import campaign_status from "@/helpers/mixins/campaign_status";
import { mapActions } from "vuex";

export default {
  filters: { date_to_age },
  mixins: [campaign_status],
  props: {
    value: {
      required: true
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true
    },
    errorMessages: {
      required: false,
      default: null
    },
    displayDraft: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    items: [],
    search: "",
    loading: false
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["loadCampaigns"])
  },
  created() {
    this.loadCampaigns({
      active_campaigns: true,
      limit: 100,
      page: 1
    }).then(campaigns => {
      if (!this.displayDraft) {
        this.items = campaigns.response.filter(item => {
          return this.getCampaignStatus(item).name !== "draft";
        });
      } else {
        this.items = campaigns.response;
      }
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
