export const MEDIA_TYPE_OTHER = 0;
export const MEDIA_TYPE_IMAGE = 1;
export const MEDIA_TYPE_VIDEO = 2;
export const MEDIA_TYPE_ALBUM = 3;

export const MEDIA_TYPES = [
    "MEDIA_TYPE_OTHER",
    "MEDIA_TYPE_IMAGE",
    "MEDIA_TYPE_VIDEO",
    "MEDIA_TYPE_ALBUM"
];