<template>
  <div class="_media-content">
    <template v-if="files.length === 0">
      <v-img
         :src="noContentImage"
      ></v-img>
    </template>
    <template v-else>
      <v-img
         v-if="isImage"
         :src="files[0].url"
      ></v-img>
      <v-carousel
        class="media-content-carousel"
        v-if="isAlbum"
        hide-delimiter-background
        :continuous="false"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>fa-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>fa-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item
          v-for="file in files"
          :key="file.uuid"
          :src="file.url"
        ></v-carousel-item>
      </v-carousel>
      <video-player v-if="isVideo" :src="files[0].url"></video-player>
    </template>
  </div>
</template>

<style>
.media-content-carousel .v-btn--icon.v-size--small {
  width: 5px;
  height: 5px;
}
.media-content-carousel .v-btn--icon.v-size--small {
  pointer-events: none;
}
.media-content-carousel .v-btn--icon.v-size--small .v-icon {
  color: white !important;
  font-size: 6px !important;
}
.media-content-carousel
  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn--icon.v-btn--round.theme--dark.v-size--small
  .v-icon {
  opacity: 1;
}

.v-application .media-content-carousel.v-carousel .v-window__next,
.v-application .media-content-carousel.v-carousel .v-window__prev {
  background-color: transparent !important;
}

.v-card ._media-content .v-responsive, .v-card ._media-content .video-player {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>

<script>
import {
  MEDIA_TYPE_OTHER,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_ALBUM,
} from "../../helpers/types/media-type";
import VideoPlayer from "./VideoPlayer";

export default {
  name: "MediaContent",
  components: {
    VideoPlayer,
  },
  props: {
    type: {
      required: true,
      type: Number,
    },
    files: {
      required: true,
      type: Array,
    },
  },
  computed: {
    isImage: function() {
      return this.type === MEDIA_TYPE_IMAGE;
    },
    isAlbum: function() {
      return this.type === MEDIA_TYPE_ALBUM;
    },
    isVideo: function() {
      return this.type === MEDIA_TYPE_VIDEO;
    },
    isOther: function() {
      return this.type === MEDIA_TYPE_OTHER;
    },
    activeColor: function() {
      return this.saved ? "error" : "success";
    },
    noContentImage() {
      return require('@/assets/no-content-file.png');
    }
  },
};
</script>
