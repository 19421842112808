<template>
  <div
    class="video-container"
    @mouseenter="showButton"
    @mouseleave="hideButton"
  >
    <video ref="videoPlayer" class="video-player" loop :muted="getMuted">
      <source :src="src" :type="type" />
    </video>
    <div class="video-text">
      <slot />
    </div>
    <div
      v-show="buttonVisible"
      class="video-play-pause-button cursor-pointer"
      @click.stop="playPauseVideo"
      @mousedown.stop="() => {}"
    >
      <v-icon v-if="!videoPlaying" class="play-icon">fa-play</v-icon>
      <v-icon v-if="videoPlaying">fa-pause</v-icon>
    </div>
    <div
      v-show="buttonVisible && videoPlaying"
      class="video-mute-button cursor-pointer"
      @click.stop="muteUnmuteVideo"
      @mousedown.stop="() => {}"
    >
      <v-icon v-if="getMuted" style="color:#FFF !important;">fal fa-volume-mute</v-icon>
      <v-icon v-if="!getMuted" style="color:#FFF !important;">fal fa-volume</v-icon>
    </div>
  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  padding: 0;
  margin: 0;
}
.video-player {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.video-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.video-play-pause-button {
  position: absolute;
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--v-primary-base);
  background-color: white;
  opacity: 0.7;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #000;
}
.video-play-pause-button .play-icon {
  position: relative;
  left: 2px;
}
.video-mute-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.7;
  text-shadow: 1px 1px 5px black;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VideoPlayer",
  props: {
    src: {
      required: true,
      type: String,
    },
    type: {
      required: false,
      type: String,
      default: "video/mp4",
    },
  },
  data() {
    return {
      buttonVisible: true,
      videoPlaying: false,
    };
  },
  computed: {
    ...mapGetters("media", ["getPlaying", "getMuted"]),
  },
  watch: {
    videoPlaying: function(play) {
      if (play) {
        this.$refs.videoPlayer.play();
      } else {
        this.$refs.videoPlayer.pause();
        this.buttonVisible = true;
      }
    },
    getMuted: function(muted) {
      this.$refs.videoPlayer.muted = muted;
    },
    getPlaying: function(playerId) {
      this.videoPlaying = (playerId === `video_${this._uid}`);
    }
  },
  methods: {
    ...mapActions("media", ["setPlaying", "setMuted"]),
    playPauseVideo() {
      if (this.videoPlaying) {
        this.setPlaying("none");
        return;
      }

      this.setPlaying(`video_${this._uid}`);
    },
    muteUnmuteVideo() {
      this.setMuted(!this.getMuted);
    },
    showButton() {
      this.buttonVisible = true;
    },
    hideButton() {
      if (this.videoPlaying) {
        this.buttonVisible = false;
      }
    }
  }
};
</script>
